$primary: #0032FA;

@import "../node_modules/bulma/bulma.sass";
@import url(https://cdn.jsdelivr.net/npm/@mdi/font@6.9.96/css/materialdesignicons.min.css);
@import "../node_modules/@creativebulma/bulma-tooltip/src/sass/index.sass";


.button.button-progress:not(.is-loading) {
    position: relative;

    &::after {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        background: black;
        display: block;
        z-index: 1;
        width: 0%;
        opacity: 15%;
        content: " ";
        transition-property: width;
        transition-duration: inherit;
    }

    &.button-progress-active::after {
        width: 100%;
    }
}

.blink {
    animation: blink-animation 1s steps(2, start) infinite;
}
@keyframes blink-animation {
    from {
        opacity: 40%;
    }
    to {
        opacity: 100%;
    }
}
.danaa-promote-image {
    .select {
        width: 5em;
        transition: width 0.8s linear;

        &:hover {
            transition: width 0s;
            width: 10em;
        }
    }
}
th.date > span {
    writing-mode: sideways-lr;
}
.glow {
    box-shadow: $primary 0px 0px 22px;
}
